import * as React from 'react'
import { HeadFC, PageProps } from 'gatsby'
import { css } from '../../styled-system/css'
import SEO from '../components/SEO'
import { getPath } from '../components/photographie/Image'

import Logo from '../images/logo_w.svg'

import Logo404 from '../images/404/404.svg'

import LogoRocket from '../images/404/rocket.svg'

import LogoEarth from '../images/404/earth.svg'

import LogoMoon from '../images/404/moon.svg'

import LogoAstronaut from '../images/404/astronaut.svg'

import ParticlesApp from '../components/index/computer/Praticles'
import { Button } from '../components/photographie/Button'

import '../styles/keyframes.css'
import { navigate } from '@reach/router'

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <div
      className={css({
        minHeight: '100vh',
        width: '100%',
        height: '100%',
      })}
      style={{
        background: "url('" + getPath('404/bg_purple.png') + "')",
        backgroundSize: 'cover',
        backgroundPosition: 'left top',
        backgroundRepeat: 'repeat-x',
        overflow: 'hidden',
      }}
    >
      <ParticlesApp />

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          marginTop: '2.5rem',
        })}
      >
        <img
          className={css({
            w: '10%',
          })}
          src={Logo}
          alt=""
        />
      </div>

      <div
        className={css({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        })}
      >
        <img
          className={css({
            position: 'relative',
            zIndex: '100',
            pointerEvents: 'none',
          })}
          width={300}
          src={Logo404}
          alt=""
        />
        <h1
          className={css({
            textTransform: 'uppercase',
            color: '#fff',
            fontFamily: "'Exo 2' !",
            fontWeight: '600',
            lineHeight: '1.2',
            letterSpacing: '.1rem',
            fontSize: '1.5rem',
          })}
        >
          On dirait que tu es
          <br />
          perdu dans l&apos;univers
        </h1>
        <Button
          variants={{ format: 'normal', visual: 'secondary' }}
          className={css({
            color: '#fff !',
            borderRadius: '2rem',
            borderColor: '#D9C58B',
            fontFamily: "'Exo 2' !",
            fontWeight: '500',
            fontSize: '1rem',
            marginTop: '1rem',
            _hover: {
              backgroundColor: '#D9C58B !',
            },
          })}
          onClick={() => navigate('/')}
        >
          RETOURNER SUR LE SITE
        </Button>
      </div>

      <div>
        <img
          className={css({
            zIndex: '95 !',
            position: 'absolute',
            transform: 'translateX(-50px)',
            top: '75%',
            pointerEvents: 'none',
            animation: 'rocket-movement 200s infinite linear both running',
          })}
          src={LogoRocket}
          width="40px"
        />
        <div>
          <img
            className={css({
              position: 'absolute',
              top: '20%',
              left: '15%',
              zIndex: '90 !',
            })}
            src={LogoEarth}
            width="100px"
          />
          <img
            className={css({
              position: 'absolute',
              top: '12%',
              left: '25%',
            })}
            src={LogoMoon}
            width="80px"
          />
        </div>
        <div
          className={css({
            zIndex: '110 !',
            position: 'absolute',
            top: '60%',
            right: '20%',
            willChange: 'transform',
            animation: 'move-astronaut 30s infinite linear both alternate',
          })}
        >
          <img
            className={css({
              animation: 'rotate-astronaut 200s infinite linear both alternate',
            })}
            src={LogoAstronaut}
            width="140px"
          />
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => (
  <SEO
    title="404 - Digit Universe"
    description="Digit Universe Erreur 404"
    robotsContent="noindex, nofollow"
  />
)
